import { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { toast } from 'react-toastify'

import routes from 'config/routes'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import { useAuth } from 'providers/auth'
import { RelationFormFileds, relationSchema } from 'validations/relations'
import RelationTabs from 'components/molecules/relation-tabs'
import { RelationTabsValue } from 'config/constants'
import RelationTabConatiner from 'components/molecules/relation-tab-container'
import ControllerIdentityList from 'components/molecules/controller-identity-list'
import ControllerGroupList from 'components/molecules/controller-group-list'
import ControllerRelationConfig from 'components/molecules/controller-relation-config'
import RelationFormResume from 'components/molecules/relation-form-resume'
import BasicButton from 'components/atoms/basic-button'
import { LocationsGroups } from 'validations/groups'
import { Location } from 'models/locations'
import { CreateRelation } from 'models/relations'
import useCreateRelation from 'hooks/queries/relation/useCreateRelation'
import { periodTimeHandler } from 'utils/periodTimeHandler'
import FormTitle from 'components/atoms/form-title'
import useFormErrorScroll from 'hooks/useFormErrorScroll'

const defaultValues: RelationFormFileds = {
  identityIds: [],
  groupIds: [],
  locationRestriction: true,
  keys: [{ keyType: '', key: '' }],
  locations: [],
  name: '',
  domain: '',
  username: '',
  active: true,
  periodDate: { from: '', to: '' },
  periodTime: [],
  metadata: [],
}

const RelationsCreateForm = (): JSX.Element => {
  const [tabValue, setTabValue] = useState<RelationTabsValue>(RelationTabsValue.identities)

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { user } = useAuth()

  const createRelation = useCreateRelation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    trigger,
  } = useForm<RelationFormFileds>({
    defaultValues,
    resolver: yupResolver(relationSchema),
  })

  useFormErrorScroll(errors)

  const { locationRestriction: isLocationActive, identityIds, groupIds } = watch()

  const handleOnTabChange = async (tab: RelationTabsValue) => {
    if (!identityIds.length) {
      return toast(t('Seleccione al menos una identidad'), { type: 'error' })
    }

    if (!groupIds.length && tab === RelationTabsValue.config) {
      return toast(t('Seleccione al menos un grupo'), { type: 'error' })
    }

    const isConfigFullfilled = tab === RelationTabsValue.resume && (await trigger())

    if (!isConfigFullfilled && tab === RelationTabsValue.resume) {
      return toast(t('Rellene todos los campos requeridos'), { type: 'error' })
    }

    setTabValue(tab)
  }

  const onSubmit = ({ locationRestriction, locations, periodTime, ...relation }: RelationFormFileds) => {
    const locationsResult = locationRestriction
      ? locations.map(({ id }: LocationsGroups) => ({ id: parseInt(id) } as Location))
      : []

    const payload: CreateRelation = {
      ...relation,
      username: user?.email as string,
      locationRestriction,
      locations: locationsResult,
      periodTime: periodTimeHandler(periodTime),
    }
    createRelation.mutate(payload)
    navigate(routes.relationsList)
  }

  return (
    <Grid container data-testid='relations-create-form'>
      <PapperGridContainer>
        <GridItem item xs={12}>
          <RelationTabs onTabsChange={handleOnTabChange} value={tabValue} />
          <RelationTabConatiner value={tabValue} index={RelationTabsValue.identities}>
            <FormTitle text={t('Identidades')} />
            <ControllerIdentityList control={control} />
          </RelationTabConatiner>
          <RelationTabConatiner value={tabValue} index={RelationTabsValue.groups}>
            <FormTitle text={t('Grupos')} />
            <ControllerGroupList control={control} />
          </RelationTabConatiner>
          <RelationTabConatiner value={tabValue} index={RelationTabsValue.config}>
            <ControllerRelationConfig control={control} errors={errors} isLocationsActive={isLocationActive} created />
          </RelationTabConatiner>
          <RelationTabConatiner value={tabValue} index={RelationTabsValue.resume}>
            <RelationFormResume getValues={getValues} />
          </RelationTabConatiner>
          <Box display='flex' gap={2}>
            {tabValue !== RelationTabsValue.identities && (
              <BasicButton
                text={t('Atrás')}
                type='button'
                variant='text'
                size='small'
                handleClick={() => handleOnTabChange(tabValue - 1)}
              />
            )}
            {tabValue !== RelationTabsValue.resume && (
              <BasicButton
                text={t('Siguiente')}
                type='button'
                variant='text'
                size='small'
                handleClick={() => handleOnTabChange(tabValue + 1)}
              />
            )}
          </Box>
        </GridItem>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.relationsList} />
        {tabValue === 3 && <BasicButton handleClick={handleSubmit(onSubmit)} text={t('Guardar cambios')} />}
      </FormButtonsContainer>
    </Grid>
  )
}

export default RelationsCreateForm
