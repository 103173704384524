import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import useGetDomains from 'hooks/queries/domain/useGetDomains'
import { Domains } from 'models/domains'
import { GroupFormFields, groupSchema, LocationsGroups } from 'validations/groups'
import routes from 'config/routes'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCustomInputKeys from 'components/molecules/controller-custominput-keys'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerCustomInputLocations from 'components/molecules/controller-custominput-locations'
import ControllerCustomInputMetadata from 'components/molecules/controller-custominput-metadata'
import ControllerCustomInputPeriodTime from 'components/molecules/controller-custominput-periodtime'
import ControllerSwitch from 'components/molecules/controller-switch'
import SubmitButton from 'components/atoms/submit-button'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import { MIN_DATE_ADD } from 'config/constants'
import useCreateGroup from 'hooks/queries/group/useCreateGroup'
import { useAuth } from 'providers/auth'
import { CreateGroups } from 'models/groups'
import { Location } from 'models/locations'
import { periodTimeHandler } from 'utils/periodTimeHandler'
import FormTitle from 'components/atoms/form-title'
import FormSubtitle from 'components/atoms/form-subtitle'
import useFormErrorScroll from 'hooks/useFormErrorScroll'

import { StyledContainer } from './styled'

const defaultValues: GroupFormFields = {
  keys: [{ keyType: '', key: '' }],
  name: '',
  domain: '',
  username: '',
  active: true,
  locationRestriction: true,
  periodDate: { from: '', to: '' },
  periodTime: [],
  locations: [],
  metadata: [],
}

const GroupsCreateForm = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useAuth()

  const { response } = useGetDomains()
  const createGroup = useCreateGroup()

  const domains = response.map(({ name: domain }: Domains) => ({ value: domain, text: t(domain) }))

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<GroupFormFields>({
    defaultValues,
    resolver: yupResolver(groupSchema),
  })

  useFormErrorScroll(errors)

  const [isLocationsActive, periodDateFrom] = watch(['locationRestriction', 'periodDate.from'])

  const onSubmit = ({ locations, locationRestriction, periodTime, ...group }: GroupFormFields) => {
    const locationsResult = locationRestriction
      ? locations.map(
          ({ keyType, id, ...data }: LocationsGroups) => ({ ...data, type: keyType, id: parseInt(id) } as Location)
        )
      : []

    const payload: CreateGroups = {
      ...group,
      username: user?.email,
      locationRestriction,
      locations: locationsResult,
      periodTime: periodTimeHandler(periodTime),
    }
    createGroup.mutate(payload)
    navigate(routes.groupsList)
  }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='groups-create-form'>
      <PapperGridContainer>
        <GridItem item xs={12}>
          <FormTitle text={t('Información')} />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='domain'
            error={errors.domain}
            label={t('Dominio')}
            size='full'
            options={domains}
          />
          <ControllerCustomInputKeys control={control} error={errors} />
          <ControllerInputText
            mandatory
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre/Descripción')}
            size='full'
          />
        </GridItem>
        <GridItem item xs={12}>
          <FormTitle text={t('Fechas de vigencia')} />
          <StyledContainer direction='row'>
            <ControllerInputDateTimePicker
              mandatory
              label='Desde'
              name='periodDate.from'
              control={control}
              error={errors.periodDate?.from}
              size='full'
              minDate={dayjs().add(MIN_DATE_ADD[0], MIN_DATE_ADD[1])}
            />
            <ControllerInputDateTimePicker
              label='Hasta'
              name='periodDate.to'
              control={control}
              error={errors.periodDate?.to}
              size='full'
              minDate={dayjs(periodDateFrom).add(1, 'minute')}
            />
          </StyledContainer>
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Localizaciones')} />
          <ControllerSwitch
            label={t('Activar localizaciones')}
            name='locationRestriction'
            variant='full'
            control={control}
            autoHeight
          />
          {isLocationsActive && <ControllerCustomInputLocations control={control} error={errors} />}
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Metadatos')} />
          <ControllerCustomInputMetadata control={control} error={errors} />
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Periodos de tiempo')} />
          <FormSubtitle text={t('Si no especifica valor alguno aplica las 24h')} />
          <ControllerCustomInputPeriodTime control={control} error={errors} />
        </GridItem>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton to={routes.groupsList} />
        <SubmitButton />
      </FormButtonsContainer>
    </Grid>
  )
}

export default GroupsCreateForm
