import React, { useEffect, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

import useGetDomains from 'hooks/queries/domain/useGetDomains'
import { Domains } from 'models/domains'
import { IdentityFormFields, identitySchema } from 'validations/identities'
import routes from 'config/routes'
import ControllerInputSelect from 'components/molecules/controller-input-select'
import ControllerCustomInputKeys from 'components/molecules/controller-custominput-keys'
import ControllerInputText from 'components/molecules/controller-input-text'
import ControllerInputDateTimePicker from 'components/molecules/controller-input-datetimepicker'
import ControllerCustomInputMetadata from 'components/molecules/controller-custominput-metadata'
import SubmitButton from 'components/atoms/submit-button'
import CancelButton from 'components/atoms/cancel-button'
import FormButtonsContainer from 'components/atoms/form-buttons-container'
import PapperGridContainer from 'components/atoms/papper-grid-container-form'
import GridItem from 'components/atoms/grid-item'
import { Identity, UpdateIdentities } from 'models/identities'
import useUpdateIdentity from 'hooks/queries/identity/useUpdateIdentity'
import ControllerSwitch from 'components/molecules/controller-switch'
import BasicButton from 'components/atoms/basic-button'
import FormTitle from 'components/atoms/form-title'
import SpinnerLoading from 'components/atoms/spinner-loading'
import useFormErrorScroll from 'hooks/useFormErrorScroll'

import IdentitiesActiveGroupsList from '../identities-active-groups'

import { StyledContainer } from './styled'

interface Props {
  response: Identity
  isLoading: boolean
  id?: string
  disabled?: boolean
  historic?: boolean
}

const defaultValues: IdentityFormFields = {
  domain: '',
  name: '',
  version: 0,
  keys: [{ keyType: '', key: '' }],
  username: '',
  active: true,
  metadata: [],
}

const IdentitiesEditForm: React.FC<Props> = ({ isLoading, response, disabled, historic, id }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateIdentity = useUpdateIdentity()

  const { response: domainsResponse } = useGetDomains()

  const domains = useMemo(
    () => domainsResponse?.map?.(({ name: domain }: Domains) => ({ value: domain, text: t(domain) })),
    [domainsResponse, t]
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IdentityFormFields>({
    defaultValues,
    resolver: yupResolver(identitySchema),
  })

  useFormErrorScroll(errors)

  useEffect(() => {
    if (!response) return

    reset({
      domain: response.domain?.name || '',
      version: response.version,
      createDate: response.createDate,
      updateDate: response.updateDate,
      keys: response.keys ?? [],
      name: response.name || '',
      username: response.username,
      active: response.active,
      metadata: response.metadata,
    })
  }, [response, reset])

  const navigateToShow = () => id && navigate(routes.identitiesHistoric.replace(':id', id))

  const onSubmit = (identity: IdentityFormFields) => {
    const result: UpdateIdentities = {
      id,
      ...identity,
      name: identity.name?.trim().length ? identity.name : null,
    }
    updateIdentity.mutate(result)
    navigate(routes.identitiesList)
  }

  if (isLoading) {
    return <SpinnerLoading fullHeight />
  }

  return (
    <Grid container component='form' onSubmit={handleSubmit(onSubmit)} data-testid='identities-edit-form'>
      <PapperGridContainer spacing={2}>
        <GridItem item xs={12}>
          <FormTitle text={t('Información')} />
        </GridItem>
        <GridItem item md={6} xs={12}>
          <ControllerInputSelect
            mandatory
            control={control}
            name='domain'
            error={errors.domain}
            label={t('Dominio')}
            size='full'
            options={domains}
            disabled={disabled}
          />
          <ControllerCustomInputKeys control={control} error={errors} disabled={disabled} />
          <ControllerInputText
            control={control}
            name='name'
            error={errors.name}
            label={t('Nombre/Descripción')}
            size='full'
            disabled={disabled}
          />
        </GridItem>

        <GridItem item md={6} xs={12}>
          {!historic && (
            <ControllerSwitch label={t('Activo')} name='active' variant='full' control={control} disabled={disabled} />
          )}
          <StyledContainer direction='row' gap='2rem'>
            <ControllerInputText
              mandatory
              control={control}
              name='version'
              error={errors.version}
              label={t('Versión')}
              size={!historic ? 'medium' : 'full'}
              disabled
            />
            {!historic && (
              <BasicButton type='button' text={t('Histórico')} size='medium' handleClick={navigateToShow} />
            )}
          </StyledContainer>
          <ControllerInputDateTimePicker
            mandatory
            label={t('Fecha de creación')}
            name='createDate'
            control={control}
            error={errors.createDate}
            size='full'
            disabled
          />
          <ControllerInputDateTimePicker
            mandatory
            label={t('Fecha de modificación')}
            name='updateDate'
            control={control}
            error={errors.updateDate}
            size='full'
            disabled
          />
        </GridItem>
        <GridItem container item xs={12}>
          <FormTitle text={t('Metadatos')} />
          <ControllerCustomInputMetadata control={control} error={errors} disabled={disabled} />
        </GridItem>
        <>
          {!historic && (
            <GridItem container item xs={12}>
              <FormTitle text={t('Grupos activos')} />
              <IdentitiesActiveGroupsList id={response?.id} />
            </GridItem>
          )}
        </>
      </PapperGridContainer>
      <FormButtonsContainer>
        <CancelButton />
        {!disabled && <SubmitButton />}
      </FormButtonsContainer>
    </Grid>
  )
}

export default IdentitiesEditForm
